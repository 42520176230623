
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Skeleton } from '@mui/material';
import Router from 'next/router';
import { useEffect } from 'react';
const Index = () => {
    useEffect(() => {
        Router.push('/login');
    }, []);
    return <Skeleton />;
};
export default Index;

    async function __Next_Translate__getStaticProps__18e286372d7__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18e286372d7__ as getStaticProps }
  